<template>
  <div class="absolute flex-col items-center group-hover:flex">
    <span
      class="relative rounded z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black"
      >{{ tooltip }}</span
    >
    <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: ['show', 'tooltip'],
}
</script>

<style></style>
